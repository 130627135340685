import { useEffect } from 'react';
import Script from 'next/script';

declare global {
  interface Window {
    _walkmeConfig: { smartLoad?: true };
  }
}
const Walkme = () => {
  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    window._walkmeConfig = { smartLoad: true };
  }, []);

  return (
    <Script id="walkme" type="text/javascript" src={process.env.NEXT_PUBLIC_WALK_ME_SCRIPT_URL} />
  );
};

export default Walkme;
