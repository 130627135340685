import { OnboardingModal } from '@ascd/witsby-components';
import { PROFILE_FORM_TYPE } from '@constants';
import { useProfileForm } from '@utils';

const OnboardModal = () => {
  const {
    givenName,
    personalEmail,
    setPersonalEmail,
    initialYearOfService,
    setInitialYearOfService,
    enumeratedGrades,
    enumeratedSubjects,
    grades,
    setGrades,
    subjectAreas,
    setSubjectAreas,
    handleUpdateProfile,
    modalOff = false,
    weeklyGoal,
    setWeeklyGoal,
  } = useProfileForm(PROFILE_FORM_TYPE.UPDATE);

  return (
    <OnboardingModal
      handleSubmitForm={() =>
        handleUpdateProfile({
          grades,
          subjectAreas,
          modalOff: true,
          minutesPerWeek: weeklyGoal,
          recoveryEmail: personalEmail,
          initialYearOfService: initialYearOfService || 2000,
        })
      }
      setSubject={setSubjectAreas}
      subject={subjectAreas}
      setGradeServe={setGrades}
      gradeServe={grades}
      enumSubjects={enumeratedSubjects}
      enumGrades={enumeratedGrades}
      personalEmail={personalEmail}
      setPersonalEmail={setPersonalEmail}
      initialYearOfService={initialYearOfService}
      setInitialYearOfService={setInitialYearOfService}
      weeklyGoal={weeklyGoal}
      setWeeklyGoal={setWeeklyGoal}
      givenName={givenName}
      modalOff={modalOff}
    />
  );
};

export default OnboardModal;
